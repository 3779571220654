<script setup>
import { ref, reactive, onMounted, defineEmits } from 'vue'
import { helpers, required } from '@vuelidate/validators'
import { useToast } from 'primevue/usetoast'
import dayjs from 'dayjs'
import errorHandler from '@/helpers/errorhandler'
import FxReportContainer from '@/components/FxReportContainer'
import LaporanKeuanganService from '@/services/LaporanKeuanganService'
import DaftarKasService from '@/services/DaftarKasService'
import useVuelidate from '@vuelidate/core'
import { saveAs } from 'file-saver'

const toast = useToast()
const laporanService = new LaporanKeuanganService()
const kasService = new DaftarKasService()

let isLoadingExport = ref(false)
let dialogViewer = ref(false)
let pdfSource = ref('')
let pdfTitle = ref('PDF Viewer')
let pdfFilename = ref('')

let form = reactive({
  date_from: null,
  date_to: null,
  kas: null,
})

let submitted = ref(false)
let list_kas = ref([])

const rules = {
  date_from: {
    required: helpers.withMessage('Tanggal harus diisi.', required),
  },
  date_to: {
    required: helpers.withMessage('Tanggal harus diisi.', required),
  },
}

const v$ = useVuelidate(rules, form)

// eslint-disable-next-line no-unused-vars
const emit = defineEmits(['toggle'])

onMounted(async () => {
  const res = await kasService.get()
  list_kas.value = res.data.data
})

function search(isFormValid) {
  submitted.value = true

  if (!isFormValid) {
    return
  }

  onExport()
}

function onExport() {
  isLoadingExport.value = true
  const startDate = dayjs(form.date_from).format('YYYY-MM-DD')
  const endDate = dayjs(form.date_to).format('YYYY-MM-DD')
  let kasId = 0
  if (form.kas !== null) {
    kasId = form.kas
  } else {
    kasId = 0
  }

  laporanService
    .exportLaporanKasPemasukan(kasId, startDate, endDate)
    .then((res) => {
      const blob = new Blob([res.data], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(blob)
      pdfSource.value = pdfUrl + '#toolbar=0'
      pdfFilename.value = 'penerimaan-kas.pdf'
      pdfTitle.value = 'PDF Viewer (' + pdfFilename.value + ')'
      dialogViewer.value = true
    })

    .catch((err) => {
      errorHandler(err, 'Data Penerimaan Kas', toast)
    })
    .finally(() => (isLoadingExport.value = false))
}

function onPrint() {
  const iframe = document.createElement('iframe')
  iframe.src = pdfSource.value
  iframe.style.display = 'none'
  document.body.appendChild(iframe)

  // Use onload to make pdf preview work on firefox
  iframe.onload = () => {
    iframe.contentWindow.focus()
    iframe.contentWindow.print()
  }
}

function onDownload() {
  saveAs(pdfSource.value, pdfFilename.value)
}

function onToggle() {
  emit('toggle')
}

function resetForm() {
  submitted.value = false
  form.kas = null
  form.date_from = null
  form.date_to = null
}
</script>

<template>
  <fx-report-container
    title="Laporan Penerimaan Kas"
    :loading="isLoadingExport"
    @toggle="onToggle"
    @export="onExport"
  >
    <template #content>
      <form @submit.prevent="search(!v$.$invalid)">
        <div class="formgrid grid">
          <div class="field col-12">
            <div class="font-bold">Filter</div>
          </div>
          <div class="field col-12 md:col-4">
            <Dropdown
              v-model="form.kas"
              :options="list_kas"
              optionValue="id"
              optionLabel="nama"
              placeholder="Pilih Kas"
              class="w-full"
              filter
              showClear
            />
          </div>
          <div class="field col-12 md:col-2">
            <Calendar
              v-model="v$.date_from.$model"
              dateFormat="dd/mm/yy"
              :class="{
                'p-invalid': v$.date_from.$invalid && submitted,
              }"
              placeholder="Tanggal (dari)"
              class="w-full"
            />
            <small
              v-if="
                (v$.date_from.$invalid && submitted) ||
                v$.date_from.$pending.$response
              "
              class="p-error"
              >{{ v$.date_from.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-2">
            <Calendar
              v-model="v$.date_to.$model"
              dateFormat="dd/mm/yy"
              :class="{
                'p-invalid': v$.date_to.$invalid && submitted,
              }"
              placeholder="Tanggal (s/d)"
              class="w-full"
            />
            <small
              v-if="
                (v$.date_to.$invalid && submitted) ||
                v$.date_to.$pending.$response
              "
              class="p-error"
              >{{ v$.date_to.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-3">
            <Button
              :loading="isLoadingExport"
              type="submit"
              icon="pi pi-file-pdf"
              label="Cetak"
            />
            <Button
              type="reset"
              icon="pi pi-refresh"
              label="Reset"
              class="p-button-outlined ml-2"
              @click="resetForm"
            />
          </div>
        </div>
      </form>
      <Dialog
        :header="pdfTitle"
        v-model:visible="dialogViewer"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '90vw' }"
        :modal="true"
      >
        <div>
          <embed
            :src="pdfSource"
            type="application/pdf"
            width="100%"
            height="600px"
          />
        </div>
        <template #footer>
          <Button
            type="button"
            label="Download"
            icon="pi pi-download"
            class="p-button-text mr-2"
            @click="onDownload"
          />
          <Button
            type="button"
            label="Print"
            icon="pi pi-print"
            @click="onPrint"
          />
        </template>
      </Dialog>
    </template>
  </fx-report-container>
</template>
